import "@reach/menu-button/styles.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Building2Icon, UserIcon } from "lucide-react";
import styled from "styled-components";

import CreatePartnerDrawer from "~/components/admin/CreatePartnerDrawer";
import CreateUserDrawer from "~/components/admin/CreateUserDrawer";
import PartnerListTable from "~/components/admin/PartnerListTable";
import UserListTable from "~/components/admin/UserListTable";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "~/components/ui/sheet";
import { Button } from "~/components/ui-rework/button";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/components/ui-rework/tabs";
import useLogSessionActivity from "~/hooks/useLogSessionActivity";
import useOverrides from "~/hooks/useOverrides";
import { useStore as useRootStore } from "~/models/Root";
import { PERMISSION } from "~/models/UserManager";

const AdminRoute: React.FC = () => {
  const [isUserView, setIsUserView] = useState(false);
  const { PartnerLabel } = useOverrides();
  const partnerLabel = PartnerLabel();
  const { getAppUser } = useRootStore();
  const appUser = getAppUser();
  const [dashboardLinkValue] = useState(useLocation().state);
  const location = useLocation();
  const { logContentView } = useLogSessionActivity();
  const [isOpen, setIsOpen] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  useEffect(() => {
    if (dashboardLinkValue?.addUser) {
      setIsUserView(true);
      setIsOpen(true);
    } else if (dashboardLinkValue?.addPartner) {
      setIsUserView(false);
      setIsOpen(true);
    }
  }, [dashboardLinkValue]);

  useEffect(() => {
    if (!isOpen) setTriggerRefresh(true);
  }, [isOpen]);

  return (
    <div className="flex flex-row flex-1 w-full justify-start bg-background light overflow-hidden">
      <title>Admin - Align App</title>
      <div className="w-full h-full light bg-background">
        <Tabs
          defaultValue="partner"
          className="overflow-hidden h-full grid gap-2"
          style={{ gridTemplateRows: "auto 1fr" }}
        >
          <div className="px-3 flex flex-row items-center">
            <div className="flex flex-row">
              <TabsList className="grid grid-cols-2 w-[350px] h-fit">
                {appUser.basic_permissions.includes(
                  PERMISSION.MANAGE_PARTNERS,
                ) && (
                  <TabsTrigger
                    value="partner"
                    onClick={() => {
                      setIsUserView(false);
                      logContentView(
                        location.pathname,
                        `Manage ${partnerLabel}`,
                      );
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <Building2Icon />
                      <span className="text-base">Manage {partnerLabel}</span>
                    </div>
                  </TabsTrigger>
                )}
                {appUser.basic_permissions.includes(
                  PERMISSION.MANAGE_USERS,
                ) && (
                  <TabsTrigger
                    value="user"
                    onClick={() => {
                      setIsUserView(true);
                      logContentView(location.pathname, `Manage Users`);
                    }}
                    data-testid="admin-manage-users"
                  >
                    <div className="flex items-center gap-2">
                      <UserIcon />
                      <span className="text-base">Manage Users</span>
                    </div>
                  </TabsTrigger>
                )}
              </TabsList>
            </div>
            {((isUserView &&
              appUser.basic_permissions.includes(PERMISSION.MANAGE_USERS)) ||
              (!isUserView &&
                appUser.basic_permissions.includes(
                  PERMISSION.MANAGE_PARTNERS,
                ))) && (
              <Button
                onClick={() => {
                  setIsOpen(true);
                }}
                testid="admin-add-new"
              >
                {isUserView ? "Add New User" : "Add New Partner"}
              </Button>
            )}
          </div>
          <div className="overflow-hidden p-3 pt-0 h-full">
            <TabsContent
              value="partner"
              className="overflow-hidden h-full mt-0 pt-0"
            >
              <PartnerListTable
                triggerRefresh={triggerRefresh}
                setTriggerRefresh={setTriggerRefresh}
              />
            </TabsContent>
            <TabsContent
              value="user"
              className="overflow-hidden h-full mt-0 pt-0"
            >
              <UserListTable
                triggerRefresh={triggerRefresh}
                setTriggerRefresh={setTriggerRefresh}
              />
            </TabsContent>
          </div>
        </Tabs>
      </div>
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetContent className="!w-1/2 !min-w-[400px] p-0">
          <div className="mx-auto w-full max-w-m h-full">
            <SheetHeader className="flex flex-row justify-items-center items-center justify-between">
              <SheetTitle className="p-4 mx-2 justify-items-center align-text-center">
                {isUserView ? "Add New User" : "Add New " + partnerLabel}
              </SheetTitle>
            </SheetHeader>
            {isUserView ? (
              <CreateUserDrawer setIsOpen={setIsOpen} />
            ) : (
              <CreatePartnerDrawer setIsOpen={setIsOpen} />
            )}
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default AdminRoute;
