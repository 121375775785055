import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { SearchIcon } from "lucide-react";

import { API_PATHS } from "~/api/ApiPaths";
import PartnerDetailsDrawer from "~/components/admin/PartnerDetailsDrawer";
import ProgramDetailsDrawer from "~/components/admin/ProgramDetailsDrawer";
import { ChevronsSortIcon } from "~/components/icons/ChevronsSortIcon";
import { ScrollArea } from "~/components/ui/scroll-area";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "~/components/ui/sheet";
import { Button } from "~/components/ui-rework/button";
import { Input } from "~/components/ui-rework/input";
import {
  Pagination,
  PaginationContent,
  PaginationFirst,
  PaginationItem,
  PaginationLast,
  PaginationNext,
  PaginationPrevious,
} from "~/components/ui-rework/pagination";
import { Select } from "~/components/ui-rework/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui-rework/table";
import useAxios from "~/hooks/useAxios";
import useOverrides from "~/hooks/useOverrides";
import { cn } from "~/lib/utils";
import { useStore } from "~/models/Root";
import { IPartner, IPartnerItem } from "~/models/UserManager";

interface IPartnerListTableProps {
  triggerRefresh: boolean;
  setTriggerRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const PartnerListTable: React.FC<IPartnerListTableProps> = (props) => {
  const { triggerRefresh, setTriggerRefresh } = props;
  const displayReady = useRef(false);
  const [partnerData, setPartnerData] = useState<IPartner[]>([]);
  const { data, authToken, error, requestUrl, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const { getAuthToken } = useStore();
  const partnersFetched = useRef(false);
  const partnersSet = useRef(false);
  const finishedFetchingAll = useRef(false);
  const [currentData, setCurrentData] = useState([]);
  const { PartnerLabel, ProgramsLabel } = useOverrides();
  const partnerLabel = PartnerLabel();
  const programsLabel = ProgramsLabel();
  const [refetch, setRefetch] = useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "display_name", desc: false },
  ]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [openPartnerDetails, setOpenPartnerDetails] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [drawerData, setDrawerData] = useState<IPartner | null>(null);

  useEffect(() => {
    if (
      data &&
      currentData !== data &&
      !partnersSet.current &&
      partnersFetched.current
    ) {
      // Set partner data
      setCurrentData(data);
      const allPartnersArray: IPartner[] = [];
      data.entity.forEach((item: IPartnerItem) => {
        allPartnersArray.push({
          id: item.id,
          partner_type: item.partner_type,
          display_name: item.name,
          short_name: item.short_name,
        });
      });
      setPartnerData(allPartnersArray);
      partnersSet.current = true;
      displayReady.current = false;
      finishedFetchingAll.current = true;
      setRefetch(false);
    } else if (!partnersFetched.current || refetch) {
      // fetch partner data
      partnersFetched.current = true;
      displayReady.current = false;
      requestUrl.current = `${API_PATHS.GET_PARTNER_LIST}?permission=ManageUsers`;
      authToken.current = getAuthToken();
      fetch();
    } else if (error) {
      console.log(error);
    }
  }, [
    data,
    error,
    authToken,
    requestUrl,
    fetch,
    getAuthToken,
    currentData,
    refetch,
  ]);

  const triggleRefetch = () => {
    displayReady.current = false;
    partnersFetched.current = false;
    partnersSet.current = false;
    setRefetch(true);
  };

  useEffect(() => {
    if (triggerRefresh) {
      triggleRefetch();
      setTriggerRefresh(false);
    }
  }, [triggerRefresh, setTriggerRefresh]);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      triggleRefetch();
    }
  };

  const columns = useMemo<ColumnDef<IPartner>[]>(
    () => [
      {
        accessorKey: "short_name",
        id: "short_name",
        header: ({ column }) => {
          return (
            <div className="flex gap-2">
              <Button
                className={cn(
                  "p-0 items-center justify-start",
                  "hover:bg-transparent hover:!border-none hover:!outline-none active:bg-transparent",
                )}
                variant="ghost"
                onClick={() =>
                  column.toggleSorting(column.getIsSorted() === "asc")
                }
              >
                Code
                <ChevronsSortIcon direction={column.getIsSorted()} />
              </Button>
              <Input
                placeholder=""
                icon={<SearchIcon />}
                iconPosition="right"
                value={(column.getFilterValue() as string) ?? ""}
                onChange={(event) => column.setFilterValue(event.target.value)}
                className={cn(
                  "bg-transparent text-base",
                  "flex-grow focus:!border active:!border",
                  {
                    "border-0":
                      column.getFilterValue() === undefined ||
                      column.getFilterValue() === "",
                  },
                )}
              />
            </div>
          );
        },
      },
      {
        accessorKey: "display_name",
        id: "display_name",
        header: ({ column }) => {
          return (
            <div className="flex gap-2">
              <Button
                className={cn(
                  "p-0 items-center justify-start",
                  "hover:bg-transparent hover:!border-none hover:!outline-none active:bg-transparent",
                )}
                variant="ghost"
                onClick={() =>
                  column.toggleSorting(column.getIsSorted() === "asc")
                }
              >
                Name
                <ChevronsSortIcon direction={column.getIsSorted()} />
              </Button>
              <Input
                placeholder=""
                icon={<SearchIcon />}
                iconPosition="right"
                value={(column.getFilterValue() as string) ?? ""}
                onChange={(event) => column.setFilterValue(event.target.value)}
                className={cn(
                  "bg-transparent text-base",
                  "flex-grow focus:!border active:!border",
                  {
                    "border-0":
                      column.getFilterValue() === undefined ||
                      column.getFilterValue() === "",
                  },
                )}
                data-testid="admin-partner-filter"
              />
            </div>
          );
        },
      },
      {
        accessorKey: "partner_type",
        header: "Type",
      },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const data = row.original;
          return (
            <div className="flex flex-nowrap gap-2">
              <Button
                size="sm"
                variant="outline"
                onClick={(e) => {
                  setIsOpen(true);
                  setOpenPartnerDetails(true);
                  setDrawerData(data);
                }}
                testid={`admin-details`}
              >
                {partnerLabel} Details
              </Button>
              <Button
                className=""
                size="sm"
                variant="outline"
                onClick={(e) => {
                  setIsOpen(true);
                  setOpenPartnerDetails(false);
                  setDrawerData(data);
                }}
                testid={`admin-programs`}
              >
                {programsLabel}
              </Button>
            </div>
          );
        },
      },
    ],
    [partnerData, partnerLabel, programsLabel], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const table = useReactTable({
    data: partnerData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    autoResetPageIndex: false,
    state: {
      sorting,
      columnFilters,
    },
  });

  const [pageIndex, setPageIndex] = useState(0);
  const firstPage = () => {
    table.firstPage();
    setPageIndex(0);
  };
  const previousPage = () => {
    table.previousPage();
    setPageIndex(pageIndex - 1);
  };
  const nextPage = () => {
    table.nextPage();
    setPageIndex(pageIndex + 1);
  };
  const lastPage = () => {
    table.lastPage();
    setPageIndex(table.getPageOptions().length - 1);
  };

  return (
    <>
      <div
        className="light h-full w-full grid gap-2 overflow-hidden rounded-md border-idesign-navy-120"
        style={{ gridTemplateRows: "auto auto 1fr" }}
      >
        <ScrollArea
          className="w-full"
          horizontal
          vertical
          thumbClassName="z-50 bg-gradient-1"
        >
          <Table className="border border-idesign-navy-120">
            <TableHeader className="bg-gradient-1 dark h-16">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="hover:bg-transparent">
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className={cn(
                          "text-white border-x border-idesign-navy-120 p-2 text-base",
                          "last:border-r-0 first:border-x-0",
                          {
                            "min-w-44": header.id === "short_name",
                            "min-w-80": header.id === "display_name",
                            "min-w-[120px]": header.id === "partner_type",
                            "min-w-[227px] w-[227px]": header.id === "actions",
                          },
                        )}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={cn(
                      "hover:bg-gray-200 border-b border-idesign-navy-120 last:border-b-0 bg-white",
                      "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                    )}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className="p-2 border-x border-idesign-navy-120"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ScrollArea>
        <div className="flex flex-row items-center justify-center w-full py-1.5">
          <div className="min-w-fit font-bold">
            Showing Items {pageIndex * 10}-
            {pageIndex === table.getPageCount() - 1
              ? table.getRowCount()
              : (pageIndex + 1) * 10}{" "}
            of {table.getRowCount()}
          </div>
          {table.getPageCount() > 1 ? (
            <Pagination className="w-fit mx-0 pr-1 md:absolute right-0">
              <PaginationContent>
                <PaginationItem>
                  <PaginationFirst
                    onClick={firstPage}
                    disabled={!table.getCanPreviousPage()}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={previousPage}
                    disabled={!table.getCanPreviousPage()}
                  />
                </PaginationItem>
                Page
                <Select
                  fieldName="pageSelect"
                  options={table.getPageOptions().map((page) => ({
                    label: (page + 1).toString(),
                    value: page.toString(),
                  }))}
                  handleChange={(_, newValue) => {
                    setPageIndex(parseInt(newValue));
                    table.setPageIndex(parseInt(newValue));
                  }}
                  value={pageIndex.toString()}
                  className="w-min"
                />
                <PaginationItem>
                  <PaginationNext
                    disabled={!table.getCanNextPage()}
                    onClick={nextPage}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLast
                    disabled={!table.getCanNextPage()}
                    onClick={lastPage}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          ) : null}
        </div>
      </div>
      <Sheet open={isOpen} onOpenChange={toggleDrawer}>
        <SheetContent className="!w-1/2 !min-w-[400px] p-0">
          <div className="mx-auto w-full max-w-m h-full">
            <SheetHeader className="flex flex-row justify-items-center items-center justify-between">
              <SheetTitle className="p-4 mx-2 justify-items-center align-text-center">
                {openPartnerDetails ? partnerLabel + " Details" : programsLabel}
              </SheetTitle>
            </SheetHeader>
            {openPartnerDetails ? (
              <PartnerDetailsDrawer partnerDetailsData={drawerData} />
            ) : (
              <ProgramDetailsDrawer programDetailsData={drawerData} />
            )}
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default PartnerListTable;
